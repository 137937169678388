import { IThunk } from "../reducers";
import { ITodosAction } from "../reducers/todos";
import { ISortKey, ISortOrder } from "../types/sort";
import { IDatetime, IUpdatingTodo, ITodoStatus } from "../types/todo";
import { setSortingKey } from "./sort";
import { getSortState } from "../selectors/sort";

export const createTodoAction = (todo: IUpdatingTodo): ITodosAction => ({
  type: "CREATE",
  ...todo
});

export const deleteTodoAction = (created_at: IDatetime): ITodosAction => ({
  type: "DELETE",
  created_at
});

export const updateTodoAction = (todo: IUpdatingTodo): ITodosAction => ({
  type: "UPDATE",
  ...todo
});

export const sortTodosByKeyAction = (
  key: ISortKey,
  order: ISortOrder
): ITodosAction => ({
  type: "SORT_BY_KEY",
  key,
  order
});

export const updateAndSortAction = (todo: IUpdatingTodo): IThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  const { key, order } = getSortState(state);

  dispatch(updateTodoAction(todo));
  dispatch(sortTodosByKeyAction(key, order));
};

export const createAndSortAction = (
  todo: IUpdatingTodo,
  sort: boolean = false
): IThunk => (dispatch, getState) => {
  dispatch(createTodoAction(todo));

  if (sort) {
    const { key, order } = getState().sort;
    dispatch(sortTodosByKeyAction(key, order));
  } else {
    dispatch(setSortingKey("custom"));
  }
};

export const setTodoStatusAction = (
  created_at: IDatetime,
  new_status: ITodoStatus,
  old_index: number,
  new_index: number
): IThunk => dispatch => {
  dispatch(setSortingKey("custom"));
  dispatch({
    type: "UPDATE_STATUS",
    created_at,
    new_status,
    old_index,
    new_index
  });
};
