import { ISortOrder, ISortKey } from "../types/sort";
import { DEFAULT_SORT_KEY, DEFAULT_SORT_ORDERS } from "../lib/consts";

export interface ISortTodosAction {
  type: "TOGGLE_SORTING_BY_KEY" | "SET_SORTING_KEY";
  key: ISortKey;
  order?: ISortOrder;
}
export interface ISortTodosState {
  order: ISortOrder;
  key: ISortKey;
}

export const sortTodosState: ISortTodosState = {
  order: DEFAULT_SORT_ORDERS,
  key: DEFAULT_SORT_KEY,
};

export const sortTodosReducer = (
  state: ISortTodosState = sortTodosState,
  action: ISortTodosAction,
) => {
  switch (action.type) {
    case "SET_SORTING_KEY": {
      const { key } = action;
      return {
        ...state,
        key,
      };
    }

    case "TOGGLE_SORTING_BY_KEY": {
      const { key } = action;
      return {
        key,
        order: state.order === "ASC" ? "DESC" : "ASC",
      } as ISortTodosState;
    }

    default:
      return state;
  }
};
