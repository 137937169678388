import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd";
import { FiPlusCircle } from "react-icons/fi";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { setTodoStatusAction } from "../actions/todos";
import { STATUSES, STATUS_LABELS } from "../lib/consts";
import { IState } from "../reducers";
import { getTodos } from "../selectors/todos";
import { ITodoStatus } from "../types/todo";
import { EditTodo, NewTodo } from "./Todo";

const TodosWrapper = styled.div``;
const TodoWrapper = styled.div<{ isDragging?: boolean; isNewTodo?: boolean }>`
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 20px;
  margin-bottom: 10px;
  background-color: ${p =>
    p.isDragging || p.isNewTodo ? "#e9ecef" : "#fefefe"};
`;

const DroppableColumn = styled.div``;
const DroppableColumnContent = styled.div`
  border: 1px solid #eaeaea;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 5px;
  min-height: 150px;
`;
const Column = styled.div<{ isDraggingOver: boolean }>`
  background-color: ${p => (p.isDraggingOver ? "#fefefe" : "transparent")};
`;

// redux HOC
const connector = connect((state: IState) => ({ todos: getTodos(state) }), {
  setTodoStatusAction
});

interface ITodosProps extends ConnectedProps<typeof connector> {}
interface ITodosState {
  new_todo: ITodoStatus | null;
}

class Todos extends React.Component<ITodosProps, ITodosState> {
  constructor(props: ITodosProps) {
    super(props);
    this.state = { new_todo: null };
  }

  onDragEnd = (result: DropResult) => {
    const { setTodoStatusAction } = this.props;
    const { destination, source, draggableId } = result;

    // ignore if task iz dropped outside droppable area
    if (!destination) {
      return;
    }

    // ignore if new location is same as previous
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // when todo item is moved, we need to update redux store
    setTodoStatusAction(
      parseInt(draggableId, 10),
      destination.droppableId as ITodoStatus,
      source.index,
      destination.index
    );
  };

  finishCreatingNewTodo = () => this.setState({ new_todo: null });

  droppableArea = () =>
    STATUSES.map(column => (
      <DroppableColumn key={column} className="col-md-4 mb-3">
        <DroppableColumnContent>
          <h3 className="text-secondary d-flex justify-content-between">
            <div>{STATUS_LABELS[column]}</div>
            <div>
              {Boolean(this.state.new_todo) || (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.setState({ new_todo: column })}
                >
                  <FiPlusCircle size={18} />
                </button>
              )}
            </div>
          </h3>
          {this.state.new_todo === column && (
            <TodoWrapper isNewTodo={Boolean(this.state.new_todo)}>
              <NewTodo
                finishCreatingNewTodo={this.finishCreatingNewTodo}
                status={column}
              />
            </TodoWrapper>
          )}
          <Droppable droppableId={column}>
            {(provided, snapshot) => (
              <Column
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {this.draggableArea(column)}
                {provided.placeholder}
              </Column>
            )}
          </Droppable>
        </DroppableColumnContent>
      </DroppableColumn>
    ));

  draggableArea = (column: ITodoStatus) =>
    this.props.todos
      .filter(todo => todo.status === column)
      .map((todo, index) => (
        <Draggable
          key={todo.created_at as any}
          index={index}
          draggableId={(todo.created_at as any).toString()}
        >
          {(provided, snapshot) => (
            <TodoWrapper
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
            >
              <EditTodo {...todo} index={index} />
            </TodoWrapper>
          )}
        </Draggable>
      ));

  render() {
    return (
      <TodosWrapper className="row">
        <DragDropContext onDragEnd={this.onDragEnd}>
          {this.droppableArea()}
        </DragDropContext>
      </TodosWrapper>
    );
  }
}

export default connector(Todos);
