import { IThunk } from "../reducers";
import { ISortKey } from "../types/sort";
import { getSortOrder } from "../selectors/sort";
import { sortTodosByKeyAction } from "./todos";
import { ISortTodosAction } from "../reducers/sort";

export const setSortingKey = (key: ISortKey): ISortTodosAction => ({
  type: "SET_SORTING_KEY",
  key
});

export const toggleSortingByKey = (key: ISortKey): IThunk => (
  dispatch,
  getState
) => {
  dispatch({
    type: "TOGGLE_SORTING_BY_KEY",
    key
  });
  dispatch(sortTodosByKeyAction(key, getSortOrder(getState())));
};
