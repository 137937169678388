import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { toggleSortingByKey } from "../actions/sort";
import { SORT_KEYS, SORT_LABELS } from "../lib/consts";
import { getSortState } from "../selectors/sort";

const Wrapper = styled.div`
  display: flex;
  margin: 15px 0;
`;

const SortWrapper = styled.div``;
const Button = styled.button``;

const SortOrder: FunctionComponent = () => {
  const { key, order } = useSelector(getSortState);
  const dispatch = useDispatch();

  return (
    <Wrapper className="row">
      <div className="col-12">Sort by</div>
      <SortWrapper className="col-12 btn-group-vertical btn-group-sm">
        {SORT_KEYS.map(sort_key => (
          <Button
            key={sort_key}
            type="button"
            className={
              "btn btn-secondary btn-sm text-left " +
              (key === sort_key ? "active" : "")
            }
            onClick={() =>
              sort_key !== "custom" && dispatch(toggleSortingByKey(sort_key))
            }
          >
            {SORT_LABELS[sort_key]}
            {key === sort_key && sort_key !== "custom" ? (
              order === "ASC" ? (
                " ▴"
              ) : (
                " ▾"
              )
            ) : (
              <span>&nbsp;&nbsp;&nbsp;</span>
            )}
          </Button>
        ))}
      </SortWrapper>
    </Wrapper>
  );
};
export default SortOrder;
