import faker from "faker";
import { toast } from "react-toastify";

import { STATUSES, STATUS_LABELS } from "../lib/consts";
import { ISortKey, ISortOrder } from "../types/sort";
import { ITodo, ITodoStatus } from "../types/todo";

export interface ITodosAction extends Partial<ITodo> {
  type: "CREATE" | "DELETE" | "UPDATE" | "UPDATE_STATUS" | "SORT_BY_KEY";
  new_status?: ITodoStatus;
  old_index?: number;
  new_index?: number;
  key?: ISortKey;
  order?: ISortOrder;
}

export type ITodosState = ITodo[];

// populate state with fake data
export const todosState: ITodosState = Array.from<ITodo>({
  length: faker.random.number({ min: 6, max: 9 })
}).map(() => ({
  title: faker.lorem.sentence(),
  description: faker.lorem.paragraph(),
  status: faker.random.arrayElement(STATUSES),
  assigned_to: faker.name.findName(),
  created_at: faker.date.past().valueOf(),
  updated_at: faker.date.past().valueOf(),
  number_of_edits: faker.random.number(5)
}));

const todosReducer = (
  state: ITodosState = todosState,
  action: ITodosAction
) => {
  switch (action.type) {
    case "CREATE": {
      const { title, description, status, assigned_to } = action;

      // we are using integer as date representation instead of
      // Date object because of sorting, usage as todo ID and
      // possible future compatibility with localStorage/backend storing
      const created_at = new Date().valueOf();
      const updated_at = created_at;
      const number_of_edits = 0;

      // show toast notification
      toast.success("Added new todo!");

      return [
        {
          number_of_edits,
          created_at,
          updated_at,
          title,
          description,
          status,
          assigned_to
        },
        ...state
      ];
    }

    case "DELETE": {
      // show toast notification
      toast.error(`Todo is deleted!`);

      return state.filter(todo => todo.created_at !== action.created_at);
    }

    case "UPDATE": {
      const { title, description, status, assigned_to, created_at } = action;

      // show toast notification
      toast.info("Todo is updated!");

      return state.map(todo =>
        todo.created_at === created_at
          ? {
              title,
              description,
              status,
              assigned_to,
              created_at,
              updated_at: new Date().valueOf(),
              number_of_edits: todo.number_of_edits + 1
            }
          : todo
      );
    }

    case "UPDATE_STATUS": {
      const { created_at, old_index, new_index, new_status } = action;

      // check if all parameters are defined
      if (
        created_at === undefined ||
        old_index === undefined ||
        new_index === undefined ||
        new_status === undefined
      )
        return state;

      // find todo item object in state
      const todo_item: any = {
        ...state.find(
          todo => todo.created_at.toString() === created_at.toString()
        )
      };

      let new_statuses = state.filter(todo => todo.status === new_status);
      let other_statuses = state.filter(todo => todo.status !== new_status);

      // todo item was not switched to other column
      if (todo_item.status === new_status) {
        // swap positions in array
        new_statuses.splice(new_index, 0, new_statuses.splice(old_index, 1)[0]);
      }
      // column is switched
      else {
        // show toast notification
        toast.success(`Changed status to: '${STATUS_LABELS[new_status]}'`);

        todo_item.status = new_status;
        // add todo item to new statuses array
        new_statuses.splice(new_index, 0, todo_item);
        // remove todo item from other statuses array
        other_statuses = other_statuses.filter(
          todo => todo.created_at.toString() !== created_at.toString()
        );
      }

      // join both arrays
      return [...new_statuses, ...other_statuses];
    }

    case "SORT_BY_KEY": {
      const { key, order } = action;

      // exit if required parameters are undefined
      if (key === undefined || order === undefined) return state;

      // ignore sorting if sorting is 'disabled'
      if (key === "custom") return state;

      // transform order string into numeric value needed for sorting algorithm
      const sort_order = order === "ASC" ? 1 : -1;

      return [...state].sort((a, b) =>
        a[key] > b[key] ? 1 * sort_order : -1 * sort_order
      );
    }

    default:
      return state;
  }
};

export default todosReducer;
