import { ISortKey, ISortOrder } from "../types/sort";
import { ITodoStatus } from "../types/todo";

export const STATUSES: ITodoStatus[] = ["TODO", "IN_PROGRES", "DONE"];
export const DEFULT_STATUS: ITodoStatus = "TODO";
export const STATUS_LABELS: { [index in ITodoStatus]: string } = {
  TODO: "Todo",
  IN_PROGRES: "In Progress",
  DONE: "Done",
};

export const SORT_KEYS: ISortKey[] = [
  "title",
  "description",
  "status",
  "assigned_to",
  "created_at",
  "updated_at",
  "number_of_edits",
  "custom",
];
export const SORT_LABELS: { [index in ISortKey]: string } = {
  title: "Title",
  description: "Description",
  status: "Status",
  assigned_to: "Assigned to",
  created_at: "Created at",
  updated_at: "Updated at",
  number_of_edits: "No. of edits",
  custom: "Custom",
};

export const SORT_ORDERS: ISortOrder[] = ["ASC", "DESC"];
export const DEFAULT_SORT_ORDERS: ISortOrder = "ASC";
export const DEFAULT_SORT_KEY: ISortKey = "custom";
