import React from "react";
import styled from "styled-components";
import SortOrder from "./SortOrder";
import Todos from "./Todos";

const HeaderWrapper = styled.div``;

const Header = () => (
  <div className="row mb-3 mt-3">
    <div className="col">
      <div className="row jumbotron mb-0 p-5">
        <div className="col-lg-10">
          <h1 className="display-4">Kanban Board</h1>
          <p className="lead">
            A Kanban board is a work and workflow visualization tool that
            enables you to optimize the flow of your work.
          </p>
          <hr className="my-4" />
          <p>
            Technologies used: Typescript, React, Redux, React Beautiful DND,
            React-Toastify, Styled Components, Bootstrap
          </p>
        </div>
        <div className="col-lg-2">
          <SortOrder />
        </div>
      </div>
    </div>
  </div>
);

const App = () => {
  return (
    <div className="container">
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>

      <Todos />
    </div>
  );
};

export default App;
