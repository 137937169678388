import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";
import { ISortTodosState, sortTodosReducer } from "./sort";
import todosReducer, { ITodosState } from "./todos";

export const reducers = combineReducers({
  todos: todosReducer,
  sort: sortTodosReducer
});

// export type IState = ReturnType<typeof reducers>;
export interface IState {
  todos: ITodosState;
  sort: ISortTodosState;
}

export type IThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IState,
  unknown,
  Action<string>
>;
