import React, {
  FunctionComponent,
  memo,
  useEffect,
  useRef,
  useState
} from "react";
import TextareaAutosize from "react-autosize-textarea";
import { FaRegSave } from "react-icons/fa";
import { GoTrashcan } from "react-icons/go";
import { IoMdPerson } from "react-icons/io";
import { WiTime12, WiTime7 } from "react-icons/wi";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  createAndSortAction,
  deleteTodoAction,
  updateAndSortAction
} from "../actions/todos";
import { DEFULT_STATUS, SORT_LABELS } from "../lib/consts";
import { ITodo } from "../types/todo";

const Wrapper = styled.div``;
const AssignedToIcon = styled.span`
  border: none;
  background-color: transparent;
`;

interface ITodoProps extends Partial<ITodo> {
  finishCreatingNewTodo?: () => void;
  newTodo?: boolean;
  index?: number;
}

const Todo: FunctionComponent<ITodoProps> = props => {
  const dispatch = useDispatch();
  const titleRef = useRef<HTMLInputElement>(null);

  const defaultTitle = props.title || "";
  const defaultDescription = props.description || "";
  const defaultStatus = props.status || DEFULT_STATUS;
  const defaultAssignedTo = props.assigned_to || "";
  const created_at = props.created_at || new Date().valueOf();
  const updated_at = props.updated_at || new Date().valueOf();
  const number_of_edits = props.number_of_edits || 0;

  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [status, setStatus] = useState(defaultStatus);
  const [assigned_to, setAssignedTo] = useState(defaultAssignedTo);

  const [validTitle, setTitleValidation] = useState(true);

  // if this is new todo, focus input on todo title
  useEffect(() => {
    if (props.newTodo && titleRef && titleRef.current) {
      titleRef.current.focus();
    }
  }, [props.newTodo]);

  // if redux sends new todo item, update state
  useEffect(() => {
    setTitle(defaultTitle);
    setDescription(defaultDescription);
    setStatus(defaultStatus);
    setAssignedTo(defaultAssignedTo);
  }, [defaultTitle, defaultDescription, defaultStatus, defaultAssignedTo]);

  const saveChanges = () => {
    // validation
    if (title.trim() === "") {
      setTitleValidation(false);

      if (props.newTodo && titleRef && titleRef.current) {
        titleRef.current.focus();
      }

      return;
    } else {
      setTitleValidation(true);
    }

    // check if this is new todo or existing one
    if (props.newTodo) {
      // dispatch redux action which will save new todo into the store
      dispatch(
        createAndSortAction({
          title,
          description,
          status,
          assigned_to,
          created_at
        })
      );
      // notify parent component to destroy this component instance
      props.finishCreatingNewTodo && props.finishCreatingNewTodo();
    }
    // update existing todo
    else {
      dispatch(
        updateAndSortAction({
          title,
          description,
          status,
          assigned_to,
          created_at
        })
      );
    }
  };

  const deleteTodo = () => {
    // check if this is new todo or existing one
    if (props.newTodo) {
      // notify parent component to destroy this component instance
      props.finishCreatingNewTodo && props.finishCreatingNewTodo();
    } else {
      dispatch(deleteTodoAction(created_at));
    }
  };

  const is_changed =
    defaultTitle !== title ||
    defaultDescription !== description ||
    defaultStatus !== status ||
    defaultAssignedTo !== assigned_to;

  return (
    <Wrapper>
      <div>
        <div className="form-group">
          <input
            placeholder={SORT_LABELS.title}
            type="text"
            className={
              "form-control font-weight-bold " +
              (validTitle ? "" : "is-invalid")
            }
            value={title}
            onChange={e => setTitle(e.target.value)}
            ref={titleRef}
          />
          <div className="invalid-feedback">Title field is required</div>
        </div>
        <div className="form-group">
          <TextareaAutosize
            placeholder={SORT_LABELS.description}
            className="form-control"
            rows={3}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <AssignedToIcon className="input-group-text" id="basic-addon1">
                <IoMdPerson />
              </AssignedToIcon>
            </div>
            <input
              placeholder={SORT_LABELS.assigned_to}
              className="form-control"
              type="text"
              value={assigned_to}
              onChange={e => setAssignedTo(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group"></div>

        <hr />
        <div className="form-group row">
          <label htmlFor="created_at" className="col-sm-5 col-form-label">
            {SORT_LABELS.created_at}
          </label>
          <div className="col-sm-7 col-form-label d-flex justify-content-end">
            <div>
              <span className="badge badge-light">
                <WiTime12 /> {new Date(created_at).toLocaleString("de")}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="updated_at" className="col-sm-5 col-form-label">
            {SORT_LABELS.updated_at}
          </label>
          <div className="col-sm-7 col-form-label d-flex justify-content-end">
            <div>
              <span className="badge badge-light">
                <WiTime7 /> {new Date(updated_at).toLocaleString("de")}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="number_of_edits" className="col-sm-5 col-form-label">
            {SORT_LABELS.number_of_edits}
          </label>
          <div className="col-sm-7 col-form-label d-flex justify-content-between">
            <span>{number_of_edits}</span>
            <div>
              {is_changed && (
                <button
                  className="btn btn-success btn-sm ml-1"
                  type="button"
                  onClick={saveChanges}
                  title="Save Changes"
                >
                  <FaRegSave />
                </button>
              )}
              <button
                className="btn btn-danger btn-sm ml-1"
                type="button"
                onClick={deleteTodo}
                title="Delete Item"
              >
                <GoTrashcan />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export const EditTodo: FunctionComponent<ITodoProps> = memo(props => (
  <Todo {...props} newTodo={false} />
));
export const NewTodo: FunctionComponent<ITodoProps> = memo(props => (
  <Todo {...props} newTodo={true} />
));
